import React from "react";
import { Link } from "react-router-dom";

// CUSTOM HOME SERVICES
import "./homeServices.css";

const HomeServices = () => {
  return (
    <>
      <div className="home-services-container container" id="services">
        {/* hair style services */}
        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/home-services/hair-style.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Hair Styling</h5>
            <p className="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>
        {/* Nails Services */}
        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/home-services/nail.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Nails</h5>
            <p className="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>
        {/* Facial services */}
        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/home-services/facial.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Facials</h5>
            <p className="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>

        {/* Makeup services */}
        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/home-services/makeup.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Makeup</h5>
            <p className="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>
        {/* Mani-Pedi Services */}
        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/home-services/mani-pedi.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Mani-Pedi</h5>
            <p className="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>
        {/* Waxing Services */}
        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/home-services/waxing.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Waxing</h5>
            <p className="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeServices;
