import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// CUSTOM COMPONENTS IMPORT
import MainHome from "./core/home/MainHome";
import MainNavbar from "./core/navbar/main-navbar/MainNavbar";
import TopNavbar from "./core/navbar/top-navbar/TopNavbar";
import CustomerReviews from "./core/customer-reviews/CustomerReviews";
import GoogleMap from "./core/home/home-google-map/GoogleMap";
import Footer from "./core/footer/Footer";
import AboutPage from "./core/about-page/AboutPage";
import Gallery from "./core/gallery/Gallery";
import Offer from "./core/offer/Offer";
import ContactUs from "./core/contact-us/ContactUs";

const ProjectRoutes = () => {
  return (
    <>
      <Router>
        <TopNavbar />
        <MainNavbar />
        <Routes>
          <Route exact path="/" element={<MainHome />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/offers" element={<Offer />} />
          <Route exact path="/contact" element={<ContactUs />} />
        </Routes>
        {/* CUSTOMER REVIEWS SECTION */}
        <CustomerReviews />
        {/* GOOGLE MAP LOCATION */}
        <GoogleMap />
        {/* FOOTER */}
        <Footer />
      </Router>
    </>
  );
};

export default ProjectRoutes;
