import React from "react";
import { Link } from "react-router-dom";

// CUSTOM CSS IMPORT
import "./offer.css";

const Offer = () => {
  return (
    <>
    <h2 className="page-heading text-center">Our Monthly Offer</h2>
      <div className="offer-page-container container">
        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/offer/offer-01.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Any Facial</h5>
            <p className="card-text">Offer valid only till 30th Sep</p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>

        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/offer/offer-02.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Hair Straightening</h5>
            <p className="card-text">Valid Till - 30th Sep Only</p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>

        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/offer/offer-03.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Global Hair Color</h5>
            <p className="card-text">Valid Till - 30th Sep Only</p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>

        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/offer/offer-04.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Hair Spa</h5>
            <p className="card-text">Valid Till - 30th Sep Only</p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>

        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/offer/offer-05.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Hair Cut, Wash and Style</h5>
            <p className="card-text">Valid Till - 30th Sep Only</p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>

        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/offer/offer-06.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Global Hair Color</h5>
            <p className="card-text">Valid Till - 30th Sep Only</p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>

        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/offer/offer-07.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Bikini Waxing</h5>
            <p className="card-text">Valid Till - 30th Sep Only</p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>

        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/offer/offer-08.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Cheryl's Essential Facial</h5>
            <p className="card-text">Valid Till - 30th Sep Only</p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>

        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/offer/offer-09.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">FH, HL Waxing</h5>
            <p className="card-text">Valid Till - 30th Sep Only</p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>

        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/offer/offer-10.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">LYN Gel Polish Nail</h5>
            <p className="card-text">Valid Till - 30th Sep Only</p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>

        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/offer/offer-11.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Manicure Pedicure</h5>
            <p className="card-text">Valid Till - 30th Sep Only</p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>

        <div className="card" style={{ width: "20rem" }}>
          <img
            src="./images/offer/offer-12.jpg"
            className="card-img-top"
            alt="Hair services..."
          />
          <div className="card-body">
            <h5 className="card-title">Party Makeup</h5>
            <p className="card-text">Valid Till - 30th Sep Only</p>
            <Link to="/contact" className="btn btn-danger">
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Offer;
