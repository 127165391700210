import React from 'react'
import Slider from "react-slick";

// Custom CSS import
import './customerReviews.css'

// react external liberary
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const CustomerReviews = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className=" container-fluid text-center customer-review-container mt-5">
                <h2 className='feedback-section-heading' style={{ color: "#D9DDDC" }} >Our Customer Reviews</h2>
                <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", margin: "20px auto" }} />
                <Slider {...settings} className="container my-5">
                    <div>
                        <img src="./images/general-icons/female.png" alt="" className="customer-photo" />
                        <p className="customer-words my-3">Extremely happy with the service. Got a haircut for my son and myself. Was done very professionally and came out well by Zafar Ji. Priya too is a beautician there and extremely good at her work. Front desk is also efficient in managing the salon. Overall happy.  </p>
                        <h6 className="customer-name" >Neha S</h6>
                    </div>
                    <div>
                        <img src="./images/general-icons/female.png" alt="" className="customer-photo" />
                        <p className="customer-words my-3">I had a very good experience at Matrix Salon Danishkunj.. Praveen did an amazing job with my hair color and spa.. I have gotten it done by him multiple times and he puts in extra efforts to give you a holistic experience.. definitely recommend the place and esp Praveen as your stylist..</p>
                        <h6 className="customer-name">Ashima Saxena</h6>
                    </div>
                    <div>
                        <img src="./images/general-icons/female.png" alt="" className="customer-photo" />
                        <p className="customer-words my-3">I visited Matrix 2 months ago, I am really thankful to Raza who did my hair straightening . My hair still looks freshly done.I am very happy with the results.</p>
                        <h6 className="customer-name">Neha Singh</h6>
                    </div>
                    <div>
                        <img src="./images/general-icons/male.png" alt="" className="customer-photo" />
                        <p className="customer-words my-3">My experience with Matrix was marvelous . Mr. Praveen , hair stylist at Matrix was perfect in his work . At the Same time he was polite and caring also . I like the efficiency and hospitality of staff  and administration . Thank you all .</p>
                        <h6 className="customer-name">Navalpreet Singh</h6>
                    </div>
                    <div>
                        <img src="./images/general-icons/female.png" alt="" className="customer-photo" />
                        <p className="customer-words my-3">My experience with Matrix was great. Mr Razzaq, a very talented stylist, warmly welcomed me and did my hair exactly the way I wanted it. Great experience. 👍<span role="img" aria-label="laugh">😄</span> </p>
                        <h6 className="customer-name">Swati dubey</h6>
                    </div>
                    <div>
                        <img src="./images/general-icons/male.png" alt="" className="customer-photo" />
                        <p className="customer-words my-3">Great service by praveen sen and the matrix kolar team. Fine haircut and hairspa , would surely recommend matrix kolar for everyone.</p>
                        <h6 className="customer-name">Ameya patel</h6>
                    </div>
                </Slider>
            </div>
        </>
    )
}

export default CustomerReviews;