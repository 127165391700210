import React from 'react';

import { Link } from 'react-router-dom';

// CUSTOM CSS IMPORT
import './mainNavbar.css';

const MainNavbar = () => {
    return (
        <>
            <div className="navbar-container">
                <nav className="navbar navbar-expand-lg navbar-dark bg-light ">
                    <div className="container navbar-items-wraper container-fluid">
                        <div className="logo-section">
                            <Link className="navbar-brand" to="/"><img className='navbar-logo' src="./images/logo.png" alt="loading..." /></Link>
                        </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse " id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-spacing">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link " to="/about" tabIndex="-1" aria-disabled="true">About</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link " to="/Gallery" tabIndex="-1" aria-disabled="true">Gallery</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link " to="#services" tabIndex="-1" aria-disabled="true">Services</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link " to="/offers" tabIndex="-1" aria-disabled="true">Offer</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link " to="#special" tabIndex="-1" aria-disabled="true">Special</Link>
                                </li> */}

                                {/* <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Services
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link className="dropdown-item" to="/cosmetric-dentistry">Aesthetic &amp; Cosmetic Dentistry</Link></li>
                                        <li><Link className="dropdown-item" to="/teeth-whitening">Teeth Whitening</Link></li>
                                        <li><Link className="dropdown-item" to="/smile-design">Smile Designing</Link></li>
                                        <li><Link className="dropdown-item" to="/tooth-extraction">Wisdom Tooth Removal/Extraction</Link></li>
                                        <li><Link className="dropdown-item" to="/orthodontics">Orthodontics Treatment</Link></li>
                                        <li><Link className="dropdown-item" to="/root-canal-treatment">Root Canal Treatment/Endodontic</Link></li>
                                        <li><Link className="dropdown-item" to="/dental-implant">Dental Implant</Link></li>
                                        <li><Link className="dropdown-item" to="/child-dentistry">Child Dentistry</Link></li>
                                    </ul>
                                </li> */}

                                <li className="nav-item">
                                    {/* <Link className="nav-link " to="/online-booking" tabIndex="-1" aria-disabled="true">Book Online</Link> */}
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link " to="/contact" tabIndex="-1" aria-disabled="true">Contact Us</Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default MainNavbar