import React from "react";

// CUSTOM CSS IMPORT
import "./aboutPage.css";

const AboutPage = () => {
  return (
    <>
      <div className="about-page-container container">
        <div className="section-01">
          <div className="left-side-container">
            <div className="image-frame-container">
              <img
                src="./images/about-page/about-image-01.jpg"
                alt="About..."
                className="frame"
              />
            </div>
          </div>
          <div className="right-side-container">
            <h2 className="bold-heading">
              We're here for your Hair and Beauty Enhancement
            </h2>
            <p className="sub-heading about-home-sub-heading">
              Poonam Sinha is the Managing Proprietor of First Holistic
              Facility. She is widely recognized for her role in shaping beauty
              and treatment and for her leadership as well as her contributions
              to various organisations she worked before starting this venture.
              Poonam Sinha began her career, with Kaya Skin Clinic. She was
              instrumental in establishing Matrix Family Salon Danish Kunj and
              Leechi’s Academy, A Trained Cosmetologist from VLCC Academy Pune,
              she took on the challenge of building the nascent retail business,
              with a strong focus on technology, innovation, process
              reengineering and expansion of Beauty holistic beauty services.
            </p>
          </div>
        </div>
        <div className="section-02">
          <div className="left-side-container"></div>
          <div className="right-side-container">
            <h2 className="bold-heading">
            OUR GOAL
            </h2>
            <p className="sub-heading about-home-sub-heading">
            With Matrix Flagship Salon, you'll get salon services that are tailored to your specific needs. We offer a wide range of services, from hair styling to makeup application, and we always aim to provide our clients with the best possible experience. Our experienced and qualified staff is dedicated to giving you the best possible service, every time. And we love making sure that our clients feel loved and valued – which is why we offer free consultation.
            </p>
          </div>
          <div className="image-frame-container">
            <img
              src="./images/about-page/about-image-02.jpg"
              alt="About..."
              className="frame"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
