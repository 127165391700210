import React from 'react'

// CUSTOM CSS IMPORT
import './gallery.css'

const Gallery = () => {
  return (
    <>
        <h1 className="page-heading text-center">Our Gallery</h1>
        <div className="gallery-container container">
            <img className='gallery-image' src="./images/gallery/image-01.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-02.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-03.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-04.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-05.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-06.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-07.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-08.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-09.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-10.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-11.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-12.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-13.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-14.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-15.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-16.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-17.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-18.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-19.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-20.jpg" alt="matrix gallery" />
            <img className='gallery-image' src="./images/gallery/image-21.jpg" alt="matrix gallery" />
        </div>
    </>
  )
}

export default Gallery