import React from "react";

// CUSTOM CSS IMPORT
import './homeAboutUs.css'

const HomeAboutUs = () => {
  return (
    <>
      <div className="home-about-us-container container">
        {/* <h1 className="section-heading text-center">About-us</h1> */}
        <div className="left-side-container">
          <div className="image-frame-container">
            <img
              src="./images/home-page-images/home-about.jpg"
              alt="About..."
              className="frame"
            />
          </div>
        </div>
        <div className="right-side-container">
          <h2 className="bold-heading">Why Our Clients Choose Us</h2>
          <h6 className="sub-heading about-home-sub-heading">
            We the leading hair and beauty salon in Danish Kunj, Bhopal
            providing high-quality haircare, skincare, and makeup services to
            everyone.
          </h6>
          <p className="paragraph text-black">
            Our clients appreciate our professional approach to everything we do
            and value our level of customer service, the skills of our stylists
            and estheticians as well. Our Beauty Salon is based on the belief
            that our customers' needs are of the utmost importance. Our entire
            team is committed to meeting those needs. As a result, a high
            percentage of our business is from repeat customers.
          </p>
          <button className="outer-red hover-red curve-border home-about-button">
            <a href="/about" className="redirect-link text-black">
              Learn More
            </a>
          </button>
        </div>
      </div>
    </>
  );
};

export default HomeAboutUs;
