import React from "react";

// Custom CSS import
import "./footer.css";

// Custom components import
// import SocialMedia from "../socialMedia/SocialMedia";

const Footer = () => {
  return (
    <>
      <div className="footer-contener">
        {/* <!-- Remove the container if you want to extend the Footer to full width. --> */}
        <div className=" bg-danger">
          <footer className="text-white text-center text-lg-start bg-dark">
            {/* <!-- Grid container --> */}
            <div className=" container p-4">
              {/* <!--Grid row--> */}
              <div className=" row mt-4">
                {/* <!--Grid column--> */}
                <div className="col-lg-4 col-md-12 mb-4 mb-md-0">
                  <h5 className="text-uppercase mb-4 text-center">
                    Matrix Flagship Salon
                  </h5>

                  <p>
                    With Matrix Flagship Salon, you'll get salon services that
                    are tailored to your specific needs. We offer a wide range
                    of services, from hair styling to makeup application, and we
                    always aim to provide our clients with the best possible
                    experience.
                  </p>

                  <p>
                    Our experienced and qualified staff is dedicated to giving
                    you the best possible service, every time. And we love
                    making sure that our clients feel loved and valued – which
                    is why we offer free consultation.
                  </p>

                  <div className="mt-4">
                    {/* <SocialMedia /> */}

                    {/* <!-- default social media  --> 
                    <a type="button" className="btn btn-floating btn-light btn-lg"><i className="fab fa-facebook-f"></i></a>
                    <a type="button" className="btn btn-floating btn-light btn-lg"><i className="fab fa-dribbble"></i></a>
                    <a type="button" className="btn btn-floating btn-light btn-lg"><i className="fab fa-twitter"></i></a>
                    <a type="button" className="btn btn-floating btn-light btn-lg"><i className="fab fa-google-plus-g"></i></a>
                    */}
                  </div>
                </div>
                {/* <!--Grid column--> */}

                {/* <!--Grid column--> */}
                <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                  <h5 className="text-uppercase mb-4 text-center">
                    Opening hours
                  </h5>

                  <table className="table text-center text-white">
                    <tbody className="fw-normal">
                      <tr>
                        <td>Monday:</td>
                        <td>10am - 08pm</td>
                      </tr>
                      <tr>
                        <td>Tuesday:</td>
                        <td>9am - 08pm</td>
                      </tr>
                      <tr>
                        <td>Wednesday:</td>
                        <td>9am - 08pm</td>
                      </tr>
                      <tr>
                        <td>Thursday:</td>
                        <td>9am - 08pm</td>
                      </tr>
                      <tr>
                        <td>Friday:</td>
                        <td>9am - 08pm</td>
                      </tr>
                      <tr>
                        <td>Saturday:</td>
                        <td>9am - 08pm</td>
                      </tr>
                      <tr>
                        <td>Sunday:</td>
                        <td>9am - 08pm</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* <!--Grid column--> */}

                {/* <!--Grid column--> */}
                <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                  <h5 className="text-uppercase mb-4 pb-1 text-center">
                    Contact Information
                  </h5>

                  {/* <div className="form-outline form-white mb-4">
                    <input type="text" id="formControlLg" className="form-control form-control-lg" />
                    <label className="form-label" for="formControlLg">Search</label>
                  </div> */}

                  <ul
                    className="fa-ul contact-info-column"
                    style={{ marginLeft: "1.65em" }}
                  >
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="ms-2">
                        DK. 2 /416 ground floor, Danish Kunj Kolar Rd, beside
                        indian gas, Bhopal, Madhya Pradesh 462042
                      </span>
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="ms-2">
                        Ground Floor Avdeep Tower E-5/45 Arera Colony, Beside
                        Baghira Appartments Bhopal, Madhya Pradesh 462016
                      </span>
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-envelope"></i>
                      </span>
                      <span className="ms-2">leechidesalon@gmail.com</span>
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-phone"></i>
                      </span>
                      <span className="ms-2">+91-7554055598 / 755 310 0932 </span>
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-phone"></i>
                      </span>
                      <span className="ms-2">+91-9669322220</span>
                    </li>
                  </ul>
                </div>
                {/* <!--Grid column--> */}
              </div>
              {/* <!--Grid row--> */}
            </div>
            {/* <!-- Grid container --> */}

            {/* CALL BUTTON */}
            {/* <div className="call-button">
              <a href="tel:8976031050"> <img src="./images/icons/call-icon.png" alt="" /> </a> */}
            {/* <a href="tel:8976031050"><i className="fas fa-phone-alt fa-3x"></i> </a> */}
            {/* </div> */}

            {/* <!-- Copyright --> */}
            <div
              className=" text-center p-3 copyright-section"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
            >
              © 2022 Copyright: Matrix Flagship Salon
              <a
                className="text-white"
                href="https://www.yodiso.com/"
                target={"_blank"}
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                {" "}
                Powered by <span style={{ color: "red" }}>yodiso</span>
              </a>
            </div>
            {/* <!-- Copyright --> */}
          </footer>
        </div>
        {/* <!-- End of .container --> */}
      </div>

      {/* CALL BUTTON */}
      <div className="call-button">
        <a href="tel:9300165995">
          {" "}
          <img src="./images/icons/call-icon.png" alt="" />{" "}
        </a>
        {/* <a href="tel:8976031050"><i className="fas fa-phone-alt fa-3x"></i> </a> */}
      </div>
    </>
  );
};

export default Footer;
