import React from "react";

// CUSTOM CSS IMPORT
import "./googleMap.css";

const GoogleMap = () => {
  return (
    <>
      <div className="googleMap-container">
        <h1 className="section-heading text-center">Contact Us</h1>
        <div className="google-map">
          <iframe
            title="matrix-flagship-map"
            className="matrix-flagship-map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14671.839809663645!2d77.4245076!3d23.1716617!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdfc1a015f7321c26!2sMatrix%20Flagship%20Salon!5e0!3m2!1sen!2sin!4v1668503833902!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default GoogleMap;
