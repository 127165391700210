import React from "react";

// CUSTOM CSS IMPORT
import "./contactUs.css";

const ContactUs = () => {
  return (
    <>
      <div className="page-container">
        <div className="contact-section">
          <h2
            className="text-center"
            style={{ color: "#000000", marginTop: "1rem" }}
          >
            Get In Touch
          </h2>
          <hr
            className="our-service-hr"
            style={{
              opacity: "1",
              color: "#ffbb33",
              maxWidth: "100px",
              margin: "0.5rem auto",
              height: "0.25rem",
            }}
          />
          <div className="address-container container text-center">
            <h4 className="our-location">Contact Danish Kunj</h4>
            <h6>address: </h6>
            <p>
              DK. 2 /416 ground floor, Danish Kunj Kolar Rd, beside indian gas,
              Bhopal, Madhya Pradesh 462042
            </p>
            <h4 className="contact-number">+91 - 9669 322 220</h4>
            <h4 className="contact-number">+91 - 7554 055 598</h4>
          </div>
          <div className="google-map">
            <h2 className="text-center text-danger">Google Map Location</h2>
            <iframe
              title="matrix-flagship-map"
              className="matrix-flagship-map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14671.839809663645!2d77.4245076!3d23.1716617!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdfc1a015f7321c26!2sMatrix%20Flagship%20Salon!5e0!3m2!1sen!2sin!4v1668503833902!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="address-container container text-center">
            <h4 className="our-location">Contact Arera Colony</h4>
            <h6>address: </h6>
            <p>
              Ground Floor Avdeep Tower E-5/45 Arera Colony, Beside Baghira
              Appartments Bhopal, Madhya Pradesh 462016
            </p>
            <h4 className="contact-number">+91 - 755 310 0932</h4>
            <h4 className="contact-number">+91 - 9669 322 220</h4>
          </div>

          <div className="google-map">
            <h2 className="text-center text-danger">Google Map Location</h2>
            <iframe
              title="matrix-flagship-map"
              className="matrix-flagship-map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14671.839809663645!2d77.4245076!3d23.1716617!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdfc1a015f7321c26!2sMatrix%20Flagship%20Salon!5e0!3m2!1sen!2sin!4v1668503833902!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
