import React from "react";

// CUSTOM CSS IMPORT
import "./mainHome.css";

// CUSTOM COMPONENTS IMPORT
import HomeCarousel from "./home-carousel/HomeCarousel";
import HomeAboutUs from "./about-us/HomeAboutUs";
import HomeServices from "./services/HomeServices";

const MainHome = () => {
  return (
    <>
      {/* HOME MAIN CAROUSEL */}
      <HomeCarousel />

      {/* HOME PAGE ABOUT SECTION */}
      <HomeAboutUs />

      {/* Home PAGE SERVICES SECTION */}
      <HomeServices />
    </>
  );
};

export default MainHome;
